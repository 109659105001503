.listing-card-one {
    width: 100%;
    margin: 0 10px;
  }
  
  @media (max-width: 1200px) {
    .listing-card-one {
      width: calc(100% / 3 - 20px);
    }
  }
  
  @media (max-width: 992px) {
    .listing-card-one {
      width: calc(100% / 2 - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .listing-card-one {
      width: 100%;
    }
  }
  